import React from 'react'
import { Box, Flex } from "@chakra-ui/react";
export function Footer() {
  return (
    <Box as="footer" bg="#fff"  position="relative" zIndex="10" mt='auto' >
      <Flex
        mx="auto"
        maxW="1366px"
        w="100%"
        py={{ base: "1rem", lg: "1.5rem"}}
        px={{ base: "1rem", md: "2rem", "2xl": "0" }}
        fontSize="sm"
        color="#0E0F12"
        justifyContent="space-between"
        borderTop="solid 1px #DDDDDD"
        flexDir={{base: 'column', md: 'row'}}
      >
      <Box>
      &copy; Copyright {new Date().getFullYear()} PremierFlex at 2534, All Right Reserved.
      </Box>

      <Box>
      5378 &amp; 5388, Ronald Reagan Blvd, Johnstown CO 80534
      </Box>

    </Flex>
    </Box>
  )
}
